import React from "react"
import { Layout } from "../components/Layout"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

export default function SSOGuide() {
  return (
    <Layout
      keywords="SSO with IrisAgent"
      title="SSO options offered in IrisAgent product"
      description="Documentation on how SSO (Single Sign On) works with IrisAgent."
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/sso-docs/"
        />
      </Helmet>
      {/* <!-- ======= Breadcrumbs ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>IrisAgent's Single Sign On (SSO) Guide</h2>
            <ol>
              <li>
                <Link to="/irisagent-docs/">Home</Link>
              </li>
              <li>SSO Guide</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}
      <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin: "10%" }}>

                    <h1>IrisAgent Single Sign On (SSO) Guide</h1>
                    <br/>
                    <h2>
                    Introduction
                    </h2>
                    <br/>

                    We understand that protection of customer data is one of the top priorities for every company we work with. At IrisAgent, we take privacy and security considerations into every organizational and product decision we make.

                    As a result, we provide SSO options for users to sign in to our product. We do not require or host any login credentials. We authenticate through Google, Microsoft, Salesforce, and Okta.
                    <br/>
                    <br/>
                    <h2>
                    Google SSO
                    </h2>
                    <br/>
                    We use <a href="https://developers.google.com/identity/protocols/oauth2/scopes#oauth2" target="_blank">Google OAuth2 APIs</a> for powering SSO via Google. The below scopes are requested:
                    <br/><br/><ol>
                    <li>
                    userinfo.email – View access to your primary Google Account email address
                    </li>
                    <li>
                    userinfo.profile – View access to your personal info, including any personal info you've made publicly available
                    </li>
                    </ol>
                    <br/>

                    <h2>
                    Salesforce SSO
                    </h2>
                    <br/>
                    Customers that use Salesforce Service Desk as the primary ticketing provider, should use this option. We use <a href="https://help.salesforce.com/s/articleView?id=sf.remoteaccess_oauth_tokens_scopes.htm&type=5" target="_blank">Salesforce OAuth APIs</a> for powering SSO via Salesforce. The below scopes are requested:
                    <br/><br/><ol>
                    <li>
                    api –  Allows access to the current, logged-in user’s account using APIs, such as REST API and Bulk API 2.0
                    </li>
                    <li>
                    refresh_token – Permission to generate refresh tokens
                    </li>
                    </ol>
                    <br/>

                    <h2>
                    Azure Active Directory SSO
                    </h2>
                    <br/>
                    We use <a href="https://learn.microsoft.com/en-us/azure/active-directory/develop/scopes-oidc" target="_blank">Azure OAuth APIs</a> for powering SSO via Azure. The below scopes are requested:
                    <br/><br/><ol>
                    <li>
                    user.read – Read basic user info
                    </li>
                    <li>
                    openid – Permission to get tokens for authentication
                    </li>
                    <li>
                    profile – Access the user's given name, surname, and preferred username
                    </li>
                    <li>
                    profile – Access the user's given name, surname, and preferred username
                    </li>
                    <li>
                    email – View the user’s primary email address.
                    </li>
                    </ol>
                    <br/>

                    <h2>
                    Okta SSO
                    </h2>
                    <br/>
                    We use <a href="https://developer.okta.com/docs/api/oauth2/" target="_blank">Okta OpenID Connect and OAuth APIs</a> for powering SSO via Okta. The below scopes are requested:
                    <br/><br/><ol>
                    <li>
                    Openid - openid connect
                    </li>
                    <li>
                    Email - access to end-users email claims
                    </li>
                    <li>
                    Profile - access to end-users default profile information
                    </li>
                    </ol>
                    <br/>


Please <a href="mailto:contact@irisagent.com" target="_blank">email us</a> if you have any questions or need any more details.
                    </div>
                    </div>
                    </section>

</Layout>
  )
}
